import { ToWords } from 'to-words'
import moment from 'moment'
import numeral from 'numeral'

export default {
  methods: {
    dateFormatter (value) {
      if (value) {
        return moment(value).format('Y-MM-DD')
      }
      return null
    },

    dateLongFormatter (value) {
      if (value) {
        return moment(value).format('MMMM DD, YYYY')
      }
      return null
    },

    dateDotFormatter (value) {
      if (value) {
        return moment(value).format('MM.DD.YYYY')
      }
      return null
    },

    dateShortFormatter (value) {
      if (value) {
        return moment(value).format('MMM DD, YYYY')
      }
      return null
    },

    dateTimeFormatter (value) {
      if (value) {
        return moment(value).format('Y-MM-DD HH:mm:ss')
      }
      return null
    },

    dateTimeShortFormatter (value) {
      if (value) {
        return moment(value).format('MMM DD, YYYY @ LT')
      }
      return null
    },

    dateTimeShortMilitaryFormatter (value) {
      if (value) {
        return moment(value).format('MMM DD, YYYY @ HH:mm')
      }
      return null
    },

    dateTimeLongFormatter (value) {
      if (value) {
        return moment(value).format('MMMM DD, YYYY @ LT')
      }
      return null
    },

    dateTimeLocalFormatter (value) {
      if (value) {
        return moment(value).format('YYYY-MM-DDTHH:mm')
      }
      return null
    },

    numberFormatter (value) {
      if (value) {
        return numeral(value).format('0,0.00')
      }
      return null
    },

    numberToWords (value) {
      const number = new ToWords({
        localeCode: 'en-US',
        converterOptions: {
          currency: true,
          ignoreDecimal: false,
          ignoreZeroCurrency: false,
          doNotAddOnly: false,
          currencyOptions: { // can be used to override defaults for the selected locale
            name: 'Peso',
            plural: 'Pesos',
            symbol: '',
            fractionalUnit: {
              name: 'Cent',
              plural: 'Cents',
              symbol: ''
            }
          }
        }
      })
      return number.convert(value)
    },

    objectToUrl (objects) {
      return Object.keys(objects).map(
        key => {
          if (typeof objects[key] === 'undefined' || objects[key] === 'null' || objects[key] === null) {
            return `${key}=`
          }

          if (Array.isArray(objects[key]) > 0) {
            return this.toArrayListParam(Array.from(objects[key]), key)
          }

          return `${key}=${objects[key]}`
        }
      ).join('&').toString()
    },

    toArrayListParam (list, key = 'list') {
      const mapped = list.map(item => {
        return `${key}[]=${item}`
      })

      return mapped.join('&').toString()
    },

    getCVNumber (approvable) {
      if (approvable.vouchers.length > 0) {
        return approvable.voucher.voucher_number
      }

      return null
    },

    userCurrentApprover (_val, _key, { stage, approvals }) {
      if (approvals && stage) {
        const approver = approvals.find(approver => Number(approver.stage) === Number(stage))
        if (approver) {
          return approver.approver_label
        }
      }
      return null
    },

    userApprovedBy (_val, _key, { stage, approvals }) {
      if (approvals && stage) {
        const approver = approvals.find(approver => Number(approver.stage) === Number(stage))
        if (approver) {
          return approver.approver_name
        }
      }
      return null
    },

    userApprovedAt (_val, _key, { stage, approvals }) {
      if (approvals && stage) {
        const approver = approvals.find(approver => Number(approver.stage) === Number(stage))
        if (approver) {
          return this.dateTimeShortFormatter(approver.status_at)
        }
      }
      return null
    },

    userCurrentRequestor (requestor) {
      if (requestor) {
        return `${requestor.first_name} ${requestor.last_name}`
      }
      return null
    },

    getTimeDifferenceFromLastApprover (approvals, _key, approvable) {
      if (approvals && approvable.stage) {
        const approver = approvals.find(
          approval => ![null, 'null', ''].includes(approval.status_at) && (
            Number(approval.stage) < Number(approvable.stage) && (
              Number(approval.stage) === (Number(approvable.stage) - 1)
            )
          )
        )
        return moment(approver?.status_at ? approver.status_at : approvable.created_at).fromNow()
      }
      return null
    },

    getLastApproverSeen (_approvals, _key, approvable) {
      if (approvable.approvals && approvable.stage) {
        const approver = approvable.approvals.find(
          approval => Number(approval.stage) === Number(approvable.stage)
        )
        return this.dateTimeShortFormatter(approver?.last_seen)
      }
      return null
    },

    checkNumberFormat (number) {
      const transformed = Number(number)
      if (isNaN(transformed) || typeof number === 'undefined' || number === Infinity) {
        return 0.00
      }

      return transformed
    },

    getBudget (itemCenter) {
      const totalCredit = this.checkNumberFormat(itemCenter.total_credit)
      const totalReversal = this.checkNumberFormat(itemCenter.total_reversal)
      const total = this.checkNumberFormat(
        totalCredit - totalReversal
      )
      return total
    },

    getSpent (itemCenter) {
      const totalDebit = this.checkNumberFormat(itemCenter.total_debit)
      const totalSpent = this.checkNumberFormat(itemCenter.total_spent)
      const total = this.checkNumberFormat(
        totalDebit + totalSpent
      )
      return total
    },

    getBalance (itemCenter) {
      const totalBudget = this.checkNumberFormat(this.getBudget(itemCenter))
      const totalSpent = this.checkNumberFormat(this.getSpent(itemCenter))
      const totalReturn = this.checkNumberFormat(itemCenter.total_return)
      const total = this.checkNumberFormat(
        (totalBudget + totalReturn) - totalSpent
      )
      return total
    },

    getBalancePercentage (itemCenter) {
      const totalBudget = this.checkNumberFormat(this.getBudget(itemCenter))
      const totalSpent = this.checkNumberFormat(this.getSpent(itemCenter))
      const totalReturn = this.checkNumberFormat(itemCenter.total_return)
      const total = this.checkNumberFormat(
        ((totalSpent - totalReturn) / totalBudget) * 100
      )
      return total
    }
  }
}
